
import { ButtonContent } from "../global/boton/ButtonContent";
// import { GiRibbonMedal } from "react-icons/gi";
// import { AiOutlineTeam } from "react-icons/ai";
// import { FaHome } from "react-icons/fa";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts }) {

  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]
  return (
    <>
      <div className="pic-wrapper svgHerosection1  w-full h-screen" >
        {
          BgImages.map((item, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }
        <div className="z-[4] relative w-4/5 mx-auto h-full flex md:flex-row flex-col justify-center items-center md:pb-[100px] md:pt-[300px] pt-[250px] pb-[100px]">
          <div className="md:w-1/2  w-full">
            <div className="w-full flex flex-col justify-center md:justify-start items-center">
              <h1 className="text-white text-center md:text-start text-[35px] md:text-[60px]">
                {title}
              </h1>
              <p className="text-white text-center md:text-start">
                {texts}
              </p>
            </div>
            <div className="w-full flex justify-center md:justify-start py-5">
              <ButtonContent btnStyle={'three'} />
            </div>
          </div>

          <div className="md:w-1/2 w-full h-full flex items-center justify-center md:pb-32 pb-0">
            <img
              alt="years"
              src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Años%20de%20experiencia%2F18%20Years.png?alt=media&token=9394e2a3-8c3a-4032-ada4-b04155a6514d&_gl=1*hmvg91*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5ODM0NTc5MS4yMzAuMS4xNjk4MzQ1ODAzLjQ4LjAuMA.."
              className="md:w-[300px] w-[180px] h-auto"
            />
          </div>
        </div>

      </div>
     
    </>
  );
}

export default HeroSection;
